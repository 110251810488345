#App {
	display: grid;
	grid-template-rows: auto max-content;
	box-sizing: border-box;
	height: calc(100vh - 1em);
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.chat {
	overflow-y: auto;
}

.message-content {
	overflow-wrap: anywhere;
	hyphens: auto;
}

.sender-ip {
	display: inline;
	font-style: italic;
	color: GrayText;
	margin-left: 0.2em;
	margin-right: 0.5em;
}

.message header time::before {
	content: " ";
}

.message + .message {
	border-top: 0.1em solid gray;
}

.message-form {
	display: flex;
	margin-top: 0.5em;
	font-size: 1.2em;
}

.message-form button {
	flex: initial;
	font-size: 0.75em;
}

.message-input {
	flex: auto;
	border-radius: 1em;
	padding-left: 0.45em;
	padding-right: 0.45em;
	margin-right: 0.5em;
	font-size: 1em;
}
